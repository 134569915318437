
import './Honeypotcollection.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Collectible from "./Collectible";

const collectibles = [
    {
        asset:"NAKAHONEYPOT",
        artist:"Indelible",
        asset_img:"https://arweave.net/UIsBgMBfk67Fh6TuLVer5oHnrBP9NgF1TXFJ3E1DRMg",
        supply:248,
        price:0.00056989,
        dollar_estimate:25,
        dispenser:"https://tokenscan.io/tx/88bc2dcfee307d7a7b0fe5a873d67d15a5785dff6390ed0aa712a5547a40083d",
        dispenser_receiver:"17DRZuvvm4cswBFh9zD2BMGdqi3rCNjT1J",
        
        holo_asset:"NAKAHONEYPOT.HOLO",
        holo_asset_img:"https://arweave.net/OeVVlAoNAuZm1WhAzTO2VcwuNrruQxOVRrfC8lSBhuI/t1oj7x_image.gif",
        holo_supply:50,
        holo_price:0.00137899,
        holo_dollar_estimate:95,
        holo_dispenser:"https://tokenscan.io/tx/2d513817aa64487502bb5ebbcfd4df2962407a7508e75edc5f754ca71c092773",
        holo_dispenser_receiver:"1LdBSr1qxcp2pe3RT9wiZcijQ1hXM7kvPj",

        total_supply:298,
    }
];

const Honeypotcollection = ()=>{
    return (
        <section className="nakahoneypot nakahoneypot-honeypot_collection rounded text-body-emphasis bg-body-secondary">
            
            <Row>
                <Col>
               
                <h1 className="fst-italic">RarePepeBag/NakaHoneyPot Collectibles</h1>
                </Col>
            </Row>
           
            <Row>
                
                { collectibles.map((collectible,index) => (
                    
                        <Collectible collectible={collectible}></Collectible>
                   
                ))}
            </Row>
           
      </section>
    )
}

export default Honeypotcollection