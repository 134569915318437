import axios from "axios";
import {assetsLoaded,honeypotLoaded,comparisonAssetsLoaded, comparisonAssetsUpdated} from './actions'

import rarepepes from '../assets/data/feed.json'
import assetFeed from '../assets/data/assets.json';
import kFeed from '../assets/data/kaleidoscope.json';
import others from '../assets/data/otherAssets.json';


export const loadAssetsFromAddress = async (address)=>{

  const xchain = `https://tokenscan.io/api/balances/${address}`;
  const myRarePepes = [];
  const myNotRarePepes = [];
  const names = [];
  let assets = []
  const assetData = (await axios.get(xchain).catch((e)=>{console.log(e)})).data;
  assets = [...assetData.data];
  console.log({assetData});
  if(assetData.total > 500){
    const assetData2 = (await axios.get(`${xchain}/?page=2`).catch((e)=>{console.log(e)})).data;
    assets = [...assetData.data,...assetData2.data];

  }

  assets.forEach(asset=>{
    if(rarepepes[asset.asset]){
   //   console.log(asset.asset+' rare');
      myRarePepes.push({...rarepepes[asset.asset],description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname, collections:asset.collections,card:( ((rarepepes[asset.asset].series-1)*50)+rarepepes[asset.asset].order ) })
    } else {
      if(asset.collections.indexOf('bitcoin-stamps')!==-1) {

        myNotRarePepes.push({...rarepepes[asset.asset],description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})
      
      } else {

        const assetData = assetFeed.filter(obj => {
          return obj.name === asset.asset;
        })

        let img_url = '';
        if(assetData.length){
        
          img_url = assetData[0].mediaUrl;
        
        }

        let otherPossibleImage = '';
          if(others.assets.filter((otherAsset)=>otherAsset.asset===asset.asset).length){
           
            otherPossibleImage = others.assets.filter((otherAsset)=>otherAsset.asset===asset.asset)[0].img_url;
            console.log({otherPossibleImage})
          }

        if(kFeed.filter((kaliedoscope)=>kaliedoscope.asset_name===asset.asset).length){
          const kaliedoData = kFeed.filter((kaliedoscope)=>kaliedoscope.asset_name===asset.asset)[0];
          
          myNotRarePepes.push({...rarepepes[asset.asset],img_url:kaliedoData.image?`https://www.kaleidoscopexcp.com/${kaliedoData.image}`:otherPossibleImage,description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})
        } else {

          if(img_url ===''){
            myNotRarePepes.push({...rarepepes[asset.asset],img_url:otherPossibleImage,description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})

          } else {
            myNotRarePepes.push({...rarepepes[asset.asset],img_url,description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})
          }
        }


      }
    }
    names.push(asset.asset);

  })

  const notGotRarePepes = [];

  
  for (const property in rarepepes) {
    if(names.indexOf(property)===-1) {
      notGotRarePepes.push({...rarepepes[property],description:'',holding:0,title:property, title_alt:'',collections:["rare-pepe"],card:( ((rarepepes[property].series-1)*50)+rarepepes[property].order )});
    }
  } 

  return {myRarePepes, myNotRarePepes,notGotRarePepes,rarePepes:[...myRarePepes,...notGotRarePepes]};




}


export const loadComparisonAssetsFromAddress = async (address)=>{

  const xchain = `https://tokenscan.io/api/balances/${address}`;
  const myRarePepes = [];
  let assets = []
  const assetData = (await axios.get(xchain).catch((e)=>{console.log(e)})).data;
  assets = [...assetData.data];
  console.log({assetData});
  if(assetData.total > 500){
    const assetData2 = (await axios.get(`${xchain}/?page=2`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }

  if(assetData.total > 1000){
    const assetData2 = (await axios.get(`${xchain}/?page=3`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }

  if(assetData.total > 1500){
    const assetData2 = (await axios.get(`${xchain}/?page=4`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }
  if(assetData.total > 2000){
    const assetData2 = (await axios.get(`${xchain}/?page=5`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }
  if(assetData.total > 2500){
    const assetData2 = (await axios.get(`${xchain}/?page=6`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }

  if(assetData.total > 3000){
    const assetData2 = (await axios.get(`${xchain}/?page=7`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }
  if(assetData.total > 3500){
    const assetData2 = (await axios.get(`${xchain}/?page=8`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }

  if(assetData.total > 4000){
    const assetData2 = (await axios.get(`${xchain}/?page=9`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }
  if(assetData.total > 4500){
    const assetData2 = (await axios.get(`${xchain}/?page=10`).catch((e)=>{console.log(e)})).data;
    assets = [...assets,...assetData2.data];

  }

  assets.forEach(asset=>{
    if(rarepepes[asset.asset]){
   //   console.log(asset.asset+' rare');
      myRarePepes.push({...rarepepes[asset.asset],description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname, collections:asset.collections,card:( ((rarepepes[asset.asset].series-1)*50)+rarepepes[asset.asset].order ) })
    } 


  })


  return {myRarePepes};




}

export const loadAssets = async (dispatch)=>{
  const loadAssetsAddress = await loadAssetsFromAddress('1KdX4ePLx8hmXcNvz2tD1uKgp5cHMyFDB2')
  dispatch(assetsLoaded(loadAssetsAddress));
  return loadAssetsAddress;
}
export const loadHoneypot = async (dispatch)=>{
  const loadAssetsAddress = await loadAssetsFromAddress('17DRZuvvm4cswBFh9zD2BMGdqi3rCNjT1J')
  dispatch(honeypotLoaded(loadAssetsAddress));
  return loadAssetsAddress;
}

export const loadComparisonAssets = async (dispatch,address, assets)=>{
  const loadAssetsAddress = await loadComparisonAssetsFromAddress(address);

  const needs = [];

 console.log({comparisonAssets:loadAssetsAddress});
 console.log({bagNotGOt:assets});
 loadAssetsAddress.myRarePepes.forEach((myRarePepe)=>{

    if(assets.filter((asset)=>asset.title===myRarePepe.title).length){
      needs.push(myRarePepe)
    }

 })
 console.log({needs})

  dispatch(comparisonAssetsLoaded(needs.map((asset)=>{return {...asset, selected:true}})));
  return loadAssetsAddress;
}

export const updateComparisonAssets = async(dispatch,comparisonAssets, asset, selected)=>{

    const storedComparisonAssets = [...comparisonAssets]
  
   
    storedComparisonAssets[storedComparisonAssets.indexOf(asset)] = {...asset, selected}

    dispatch(comparisonAssetsUpdated([...storedComparisonAssets]));
  


}

export const reorderComparisonAssets = async(dispatch,comparisonAssets,field)=>{
  const storedComparisonAssets = [...comparisonAssets]
  if(field !== 'quantity'){
    storedComparisonAssets.sort((a,b)=>a[field]-b[field])
  }
  else {
    storedComparisonAssets.sort((a,b)=>(a.quantity -a.burned)-(b.quantity-b.burned))
  }
  dispatch(comparisonAssetsUpdated([...storedComparisonAssets]));



}





  // export const loadHoneyPot = async (dispatch)=>{
  //   const xchain = "https://tokenscan.io/api/balances/17DRZuvvm4cswBFh9zD2BMGdqi3rCNjT1J";
  //   const myRarePepes = [];
  //   const myNotRarePepes = [];
  //   const names = [];
  //   let assets = []
  //   const assetData = (await axios.get(xchain).catch((e)=>{console.log(e)})).data;
  //   assets = [...assetData.data];
  //   console.log({assetData});
  //   if(assetData.total > 500){
  //     const assetData2 = (await axios.get(`${xchain}/?page=2`).catch((e)=>{console.log(e)})).data;
  //     assets = [...assetData.data,...assetData2.data];

  //   }
  //   // if total is over 500 scan for page 2

  //   //const kData = JSON.parse((await axios.get("https://www.kaleidoscopexcp.com/api/v1/assets/").catch((e)=>{console.log(e)})));
  //  // console.log({assetData:assetData})
  //   assets.forEach(asset=>{
  //     if(rarepepes[asset.asset]){
  //    //   console.log(asset.asset+' rare');
  //       myRarePepes.push({...rarepepes[asset.asset],description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname, collections:asset.collections,card:( ((rarepepes[asset.asset].series-1)*50)+rarepepes[asset.asset].order ) })
  //     } else {
  //       if(asset.collections.indexOf('bitcoin-stamps')!==-1) {
  
  //         myNotRarePepes.push({...rarepepes[asset.asset],description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})
        
  //       } else {
  
  //         const assetData = assetFeed.filter(obj => {
  //           return obj.name === asset.asset;
  //         })
  
  //         let img_url = '';
  //         if(assetData.length){
          
  //           img_url = assetData[0].mediaUrl;
          
  //         }

  //         let otherPossibleImage = '';
  //           if(others.assets.filter((otherAsset)=>otherAsset.asset===asset.asset).length){
  //             console.log('HIT')
  //             otherPossibleImage = others.assets.filter((otherAsset)=>otherAsset.asset===asset.asset)[0].img_url;
  //             console.log({otherPossibleImage})
  //           } else {
  //             console.log('...')
  //           }

  //         if(kFeed.filter((kaliedoscope)=>kaliedoscope.asset_name===asset.asset).length){
  //           const kaliedoData = kFeed.filter((kaliedoscope)=>kaliedoscope.asset_name===asset.asset)[0];
            
  //           myNotRarePepes.push({...rarepepes[asset.asset],img_url:kaliedoData.image?`https://www.kaleidoscopexcp.com/${kaliedoData.image}`:otherPossibleImage,description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})
  //         } else {

  //           if(img_url ===''){
  //             myNotRarePepes.push({...rarepepes[asset.asset],img_url:otherPossibleImage,description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})

  //           } else {
  //             myNotRarePepes.push({...rarepepes[asset.asset],img_url,description:asset.description,holding:asset.quantity, estimates:asset.estimated_value,title:asset.asset, title_alt:asset.asset_longname,collections:asset.collections,card:0})
  //           }
  //         }


  //       }
  //     }
  //     names.push(asset.asset);
  
  //   })
  
  //   const notGotRarePepes = [];

    
  //   for (const property in rarepepes) {
  //     if(names.indexOf(property)===-1) {
  //       notGotRarePepes.push({...rarepepes[property],description:'',holding:0,title:property, title_alt:'',collections:["rare-pepe"],card:( ((rarepepes[property].series-1)*50)+rarepepes[property].order )});
  //     }
  //   } 
  
  //   dispatch(assetsLoaded({myRarePepes, myNotRarePepes,notGotRarePepes,rarePepes:[...myRarePepes,...notGotRarePepes]}));
  //   return {myRarePepes, myNotRarePepes,notGotRarePepes,rarePepes:[...myRarePepes,...notGotRarePepes]};
  // }
